import React from 'react';

import { RenewIconProps } from 'ui-kit/icons/renew-icon/renew-icon.props';

import './renew-icon.style.scss';

const RenewtIcon = ({ className, style }: RenewIconProps) => {
    return (
        <div className={`renew-icon ${className ?? ''}`} style={style}>
            <svg width="100%" height="100%" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.66665 3.49935V5.49935L8.33331 2.83268L5.66665 0.166016V2.16602C2.71998 2.16602 0.333313 4.55268 0.333313 7.49935C0.333313 8.54602 0.63998 9.51935 1.15998 10.3394L2.13331 9.36602C1.83331 8.81268 1.66665 8.17268 1.66665 7.49935C1.66665 5.29268 3.45998 3.49935 5.66665 3.49935ZM10.1733 4.65935L9.19998 5.63268C9.49331 6.19268 9.66665 6.82602 9.66665 7.49935C9.66665 9.70602 7.87331 11.4994 5.66665 11.4994V9.49935L2.99998 12.166L5.66665 14.8327V12.8327C8.61331 12.8327 11 10.446 11 7.49935C11 6.45268 10.6933 5.47935 10.1733 4.65935Z"
                    fill="#03A4DC"
                />
            </svg>
        </div>
    );
};

export default RenewtIcon;
