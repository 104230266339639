import React from 'react';
import { default as ReactSwitch } from 'react-switch';

import { SwitchProps } from './types';

import './index.style.scss';

export const Switch: React.FC<SwitchProps> = ({ onChange, checked, disabled, className, t }) => {
    return (
        <ReactSwitch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={className}
            uncheckedIcon={
                <div className="react-switch-icon react-switch-icon__unchecked">
                    {t('components.autoRefillToggle.labelOff')}
                </div>
            }
            checkedIcon={
                <div className="react-switch-icon react-switch-icon__checked">
                    {t('components.autoRefillToggle.labelOn')}
                </div>
            }
            onColor={'#03A4DC'}
            offColor={'#F0F2F4'}
            width={63}
            height={27}
            handleDiameter={23}
            boxShadow="1px 1px 2px rgba(0, 0, 0, 0.17)"
        />
    );
};
