import { TFunction } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';

import { AutoRefillToggle } from 'components/auto-refill-toggle';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import './auto-refill-disclaimer.style.scss';

import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { medicineCabinetAutoRefillToggleBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { EasyRefillRxResult } from 'types/easy-refill';

export interface AutoRefillDisclaimerProps {
    t: TFunction<'translation'>;
    prescription: PrescriptionObjectPayload | EasyRefillRxResult;
    onAutoRefillChange: (prescription: PrescriptionObjectPayload | EasyRefillRxResult, value: boolean) => void;
}

export const AutoRefillDisclaimer: React.FC<AutoRefillDisclaimerProps> = ({ t, prescription, onAutoRefillChange }) => {
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);

    return (
        <div className="auto-refill-disclaimer-container text-center">
            <div className="auto-refill-disclaimer-icon-container">
                <AlertIcon />
                <span>{t('pages.cartOrderConfirmation.autoRefill.turnOnAutoRefillQuestion')}</span>
            </div>
            <div className="auto-refill-disclaimer">
                <p>{t('pages.cartOrderConfirmation.autoRefill.yourRxIsElegible')}</p>
                <p>{t('pages.cartOrderConfirmation.autoRefill.turnItOnMessage')}</p>
                <AutoRefillToggle
                    checked={prescription?.autoRefillEnabled}
                    onChange={(val: boolean) => onAutoRefillChange(prescription, val)}
                    t={t}
                    rxEligible={prescription?.autoRefillEligible}
                    isBusy={autoRefillToggleBusy}
                />
            </div>
        </div>
    );
};
